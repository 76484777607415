enum screen {
  MAIN,
  ABOUT,
  PRODUCTS,
  PRODUCT,
  CLUB,
  STORE,
  PROMO,
  SCAN_CHOOSE,
  SCAN_MANUAL,
  SCAN_QR,
  SCAN_METRO,
  CART,
  FAVORITES,
  REG,
  ERROR,
  QUESTIONNAIRE,
  RECIPE,
  MERCH,
  ORDER,
  STATUSES,
  DATA,
  ORDERS,
  MOVEMENTS,
  RULES,
  FAQ,
  SUPPORT,
  CONTEST
}
enum errors {
  NOT_FOUND = 1,
  INIT_DATA = 2,
  FILE_UPLOAD = 3,
  BANNED = 4
}
enum merchFilter {
  ALL,
  NEW_MERCH,
  EXCLUSIVE,
  MAIN,
  SEARCH
}
enum productFilter {
  ALL,
  VERMOUTH,
  NON_ALCOHOLIC,
  SPARKLING
}
enum sizeFilter {
  XS = 'XS',
  S = 'S',
  M = 'M',
  L = 'L',
  XL = 'XL'
}
enum status {
  GOLD = 'gold',
  SILVER = 'silver',
  BRONZE = 'bronze'
}
enum orderStatus {
  ISSUED = 1,
  IN_DELIVERY = 2,
  RECEIVED = 3
}
export {
  screen,
  errors,
  merchFilter,
  productFilter,
  sizeFilter,
  status,
  orderStatus
}