import '../../css/contest.css';
import { observer } from 'mobx-react-lite';
import BackButton from '../components/BackButton';
import State from '../data/State';
import User from '../data/User';
import { screen } from '../types/enums';

export default observer(() => {
  const scan = () => {
    User.isRegistered() ? State.setScreen(screen.SCAN_CHOOSE) : State.setScreen(screen.REG);
  }
  return (
    <div className='contest-block'>
      <BackButton callback={() => State.toBackScreen()} />
      <div className='contest-header'></div>
      <div className='contest-header-descr'>С 29 августа по 19 октября<br />дарим невероятно крутые призы за покупки<br />от двух бутылок Santo Stefano Zero в Ашан </div>
      <div className='contest-gifts-block'>
        <div className='contest-button' onClick={ scan } >Регистрируй свои чеки*<br />и становись участником акции!</div>
        <div className='contest-button-descr'>*В акции принимают участие чеки от 2-х позиций Santo Stefano Zero</div>
        <div className='contest-gifts-descr'>Каждую неделю <span>20 счастливчиков</span> разделят между собой подарочные карты Ашан и коробки с Santo Stefano Zero</div>
      </div>
      <div className='contest-condition-header'>ОБЯЗАТЕЛЬНОЕ УСЛОВИЕ:</div>
      <div className='contest-condition-block'></div>
      <div className='contest-condition-button-block'>
        <div className='contest-button' onClick={ scan } >Регистрируй свои чеки*<br />и становись участником акции!</div>
        <div className='contest-button-descr'>*В акции принимают участие чеки от 2-х позиций Santo Stefano Zero</div>
      </div>
      <div className='contest-prize-block'>
        <div className='contest-prize-header'>ГЛАВНЫЙ ПРИЗ</div>
        <div className='contest-prize-descr'><span>25 октября</span> мы объявим<br />победителя, который заберет<br /><span>главный приз – iPhone 15</span></div>
      </div>
      <div className='contest-stock-header'>В АКЦИИ УЧАСТВУЮТ:</div>
      <div className='contest-bottles-block'></div>
      <div className='contest-more-gifts-block'>
        <div className='contest-more-gifts-image'></div>
        <div className='contest-more-gifts-descr'>
          <div>Участников будет много,<br />поэтому удваивай свои шансы<br />новыми чеками. И не расстраивайся,<br />если кто-то окажется чуть удачливее тебя!<br /><br /></div>
          <div><span>Мы приготовили подарки для всех!</span></div>
        </div>
      </div>
      <div className='contest-prizes-block'></div>
      <div className='contest-prizes-button-block'>
        <div className='contest-button' onClick={ scan } >Регистрируй свои чеки*<br />и становись участником акции!</div>
        <div className='contest-button-descr'>*В акции принимают участие чеки от 2-х позиций Santo Stefano Zero</div>
      </div>
      <div className='contest-footer-block'></div>
    </div>
  );
});