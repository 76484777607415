import '../../css/orders.css';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import BackButton from '../components/BackButton';
import HeaderLogo from '../components/HeaderLogo';
import Tabbar from '../components/Tabbar';
import State from '../data/State';
import User from '../data/User';
import merch from '../data/merch';
import { screen, orderStatus } from '../types/enums';

export default observer(() => {
  const [orders, setOrders] = useState<IorderData[]>(null);
  useEffect(() => {
    axios.post(process.env.REACT_APP_API + '/getOrders', {
      init_data: User.getInitData(),
      id: User.getID()
    }).then(res => {
      if (!res.data.error) {
        setOrders(res.data.data);
      } else {
        State.setError(res.data.error_type);
        State.setScreen(screen.ERROR);
      }
    }).catch(e => State.setScreen(screen.ERROR));
  }, []);

  const getLongID = (id: number): string => {
    const length = id.toString().length;
    let result: string = '';
    for (let i = 0; i < 5 - length; i++) result += '0';
    return result + id.toString();
  }

  const getFormatDate = (date: string): string => {
    const months = [
      'января', 'февраля', 'марта', 'апреля', 
      'мая', 'июня', 'июля', 'августа', 
      'сентября', 'октября', 'ноября', 'декабря'
    ];
    const [day, month] = date.split('-');
    const monthName = months[parseInt(month, 10) - 1];
    return `${day} ${monthName}`;
  }

  const list = orders ? orders.map(data => {
    const items = data.merch.map(data => {
      const icon = merch.find(item => item.id === data).icon;
      return (
        <div key={ data } className='orders-item' style={{ backgroundImage: 'url(' + icon + ')' }}></div>
      );
    });
    const status = data.status === orderStatus.ISSUED ? 'Оформлен' : orderStatus.IN_DELIVERY ? 'В доставке' : orderStatus.RECEIVED ? 'Получен' : '';
    return (
      <div key={ data.id } className='orders-merch-block'>
        <div className='orders-info-block'>
          <div>
            <div className='orders-info-id'>Заказ { getLongID(data.id) }</div>
            <div className='orders-info-date'>от { getFormatDate(data.date) }</div>
          </div>
          <div className='orders-info-status'>{ status }</div>
        </div>
        <div className='orders-address'>Пункт выдачи по адресу: { data.address }</div>
        { items }
      </div>
    );
  }) : null;

  return (
    <div className='orders-block'>
      <BackButton callback={() => State.toBackScreen()} />
      <HeaderLogo />
      <div className='orders-header'>ЗАКАЗЫ</div>
      { list }
      { orders && orders.length === 0 && <div className='orders-empty-block'>
        <div className='orders-empty-text'>У вас еще нет заказов</div>
        <div className='gold-button' onClick={() => State.setScreen(screen.STORE)}>Перейти в каталог</div>
      </div> }
      <div className='orders-tabbar-indent'></div>
      <Tabbar opacity={ 1 } />
    </div>
  );
});