import '../../css/rules.css';
import { observer } from 'mobx-react-lite';
import BackButton from '../components/BackButton';
import HeaderLogo from '../components/HeaderLogo';
import State from '../data/State';

export default observer(() => {
  return (
    <div className='rules-block'>
      <BackButton callback={() => State.toBackScreen()} />
      <HeaderLogo />
      <div className='rules-header'>ПРАВИЛА</div>
      <div className='rules-header-content'>Здесь мы подробно расскажем о том, как получить подарки от Santo Stefano.
      Для этого перейдите по ссылке и ознакомьтесь с <a href='https://ss-bot.ru/rules.pdf' target='_blank'>правилами акции</a></div>
      <div className='rules-tabbar-indent'></div>
      <div className='rules-blur'></div>
    </div>
  );
});