import merch1 from '../../assets/media/merch/merch-1.jpeg';
import merch2 from '../../assets/media/merch/merch-2.jpeg';
import merch3 from '../../assets/media/merch/merch-3.jpeg';
import merch4 from '../../assets/media/merch/merch-4.jpeg';
import merch5 from '../../assets/media/merch/merch-5.jpg';
import merchIcon1 from '../../assets/media/merch/merch-icon-1.png';
import merchIcon2 from '../../assets/media/merch/merch-icon-2.png';
import merchIcon3 from '../../assets/media/merch/merch-icon-3.png';
import merchIcon4 from '../../assets/media/merch/merch-icon-4.png';
import merchIcon5 from '../../assets/media/merch/merch-icon-5.png';
import { merchFilter } from '../types/enums';

const merch: Imerch[] = [
  {
    id: 1,
    image: merch1,
    icon: merchIcon1,
    name: 'ПАНАМА SANTO STEFANO',
    descr: 'Легкая панама Santo Stefano с широкими полями и кантом из короткой бахромы. Благодаря аккуратной классической форме и универсальному цвету она легко сочетается с любыми элементами вашего гардероба. Perfetto!',
    price: 300,
    tag: merchFilter.MAIN,
    // discount: 4000
  },
  // {
  //   id: 2,
  //   image: merch2,
  //   icon: merchIcon2,
  //   name: 'ХУДИ SANTO STEFANO',
  //   descr: 'Худи Santo Stefano с мягкой ворсистой изнанкой обеспечивает комфорт и тепло в любое время года. Универсальный пастельный оттенок и элегантный дизайн позволяют его легко сочетать с любыми элементами повседневного гардероба. Perfetto!<br /><br />Длина по спинке:  64 см<br />Обхват груди: 144 см<br />Длина рукава: 83 см',
  //   price: 600,
  //   tag: merchFilter.MAIN,
  // },
  {
    id: 3,
    image: merch3,
    icon: merchIcon3,
    name: 'СУМКА SANTO STEFANO',
    descr: 'Голографический шоппер Santo Stefano станет незаменимым аксессуаром для города, путешествий или спортивных занятий. В разном освещении сумка раскрывает новые, неповторимые оттенки, добавляя вашему образу динамичности. Элегантные ручки цвета шампань придают ей изысканности и комфорта в использовании. Bellissimo!',
    price: 400,
    tag: merchFilter.MAIN,
    // discount: 4000
  },
  {
    id: 4,
    image: merch4,
    icon: merchIcon4,
    name: 'БАНДАНА SANTO STEFANO',
    descr: 'Бандана Santo Stefano - это современный и стильный аксессуар для повседневных образов. Идеально подойдет как для прогулки по городу, так и для смелого решения на вечерний выход. Bellissimo!<br /><br />Размер: 60x60 см<br />Материал: 85% полиэстер и 15% вискоза',
    price: 150,
    tag: merchFilter.MAIN,
    // discount: 4000
  },
  {
    id: 5,
    image: merch5,
    icon: merchIcon5,
    name: 'СВИТШОТ SANTO STEFANO',
    descr: 'Стильный свитшот Santo Stefano - манифест хорошего настроения. Петельная текстура без начеса дарит свободные ощущения, а принт говорит сам за себя: пить Santo Stefano – значит быть счастливым!<br /><br />Длина по спине: 71 см<br />Обхват груди: 63 см<br />Длина рукава: 60 см',
    price: 500,
    tag: merchFilter.NEW_MERCH,
    discount: 1000
  }
];

export default merch;