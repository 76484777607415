import brutRoseZeroIcon from '../../assets/media/stories/brut-rose-zero.png';
import zeroMiniIcon from '../../assets/media/stories/zero-mini.png';
import biancoZeroIcon from '../../assets/media/stories/bianco-zero.png';
import rossoZeroIcon from '../../assets/media/stories/rosso-zero.png';

import brutRoseZero from '../../assets/media/stories/brut-rose-zero.mp4';
import zeroMini from '../../assets/media/stories/zero-mini.mp4';
import biancoZero from '../../assets/media/stories/bianco-zero.mp4';
import rossoZero from '../../assets/media/stories/rosso-zero.mp4';

import brutRoseZeroPoster from '../../assets/media/stories/brut-rose-zero-poster.jpeg';
import zeroMiniPoster from '../../assets/media/stories/zero-mini-poster.jpeg';
import biancoZeroPoster from '../../assets/media/stories/bianco-zero-poster.jpeg';
import rossoZeroPoster from '../../assets/media/stories/rosso-zero-poster.jpeg';

const stories: Istories[] = [
  {
    id: 1,
    video: true,
    icon: brutRoseZeroIcon,
    src: brutRoseZero,
    descr: 'Наслаждайся каждым моментом с Brut Rose Zero! <a href="https://ozon.ru/t/qekb8qZ" target="_blank">Заказать</a>',
    name: 'Brut Rose Zero',
    poster: brutRoseZeroPoster
  },
  {
    id: 2,
    video: true,
    icon: zeroMiniIcon,
    src: zeroMini,
    descr: 'Наслаждайся каждым моментом с Zero Mini! <a href="https://ozon.ru/t/qekb8qZ" target="_blank">Заказать</a>',
    name: 'Zero Mini',
    poster: zeroMiniPoster
  },
  {
    id: 3,
    video: true,
    icon: biancoZeroIcon,
    src: biancoZero,
    descr: 'Наслаждайся каждым моментом с Bianco Zero! <a href="https://ozon.ru/t/qekb8qZ" target="_blank">Заказать</a>',
    name: 'Bianco Zero',
    poster: biancoZeroPoster
  },
  {
    id: 4,
    video: true,
    icon: rossoZeroIcon,
    src: rossoZero,
    descr: 'Наслаждайся каждым моментом с Rosso Zero! <a href="https://ozon.ru/t/qekb8qZ" target="_blank">Заказать</a>',
    name: 'Rosso Zero',
    poster: rossoZeroPoster
  }
];

export default stories;